/* Find any element which has a 'data-onload' function and load that to simulate an onload. 
 * This allows us to put fake onload event son things like DEV's and inputs.
 * The main use would be when you need to setup some javascript which requires the asp.net ClientID
 * passed into it.  Saves a ton of messy code behind and DOM magic storing classes in the DOM.
 */
jQuery(document).ready(function () {
    jQuery('[data-onload]').each(function () {
        eval(jQuery(this).data('onload'));
    });
});

// IE 11 polyfill for CustomEvent
(function () {

    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    window.CustomEvent = CustomEvent;
})();

let registerDoSubmitSubscription = (submitButtonClientName) => {
    let isCardinalDone = false;
    let isPayCentralDone = false;
    // We're going to use events to allow the interception of the submit button
    // We do not directly call the submit button now, the firing of this event does the postback
    // (after all other checks have been made: such as validation and 3DS)
    // This is the last step after all the client side work 
    document.addEventListener("doSubmit", function () {
        isCardinalDone = true;
        handlePostBack();
        //WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions(submitButtonClientId, "", true, "", "", false, true));
        // or
    });

    document.addEventListener("doSubmitPayCentral", () => {
        isPayCentralDone = true;
        handlePostBack();
    });

    function handlePostBack() {
        let isFrameLoaded = window.PayCentralAdapter || window.PayCentral;
        if (isCardinalDone && (isPayCentralDone || !isFrameLoaded))
            __doPostBack(submitButtonClientName, '');
    }
};

// Fire an event, a hook, which happens right before we'll POST the page to submit the payment/comboOrder.
let fireBeforeSubmitEvent = (submitButtonClientId) => {    
    let submitButton = document.getElementById(submitButtonClientId);
    let event = new CustomEvent("beforeSubmit", { bubbles: true, detail: { submitButtonId: submitButtonClientId } });
    submitButton.dispatchEvent(event);
};

//Register on for access from the global DOM (this file is our application entry point)
window.SubmitOrderButtonLinkDisplay = {
    registerDoSubmitSubscription,
    fireBeforeSubmitEvent
};